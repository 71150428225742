<template>
  <div class="PageBox">
    <div class="ScrollBox">
    <div class="header_bg">
      <div style="color: #fff;font-size: 16px;padding: 5px;text-align: center">
        <span style="padding: 10px;font-weight: bold">{{$t('user.default[0]')}}</span>
      </div>
      <div style="margin-top:20px;padding-left:5px;margin-bottom: 30px">
            <div class="Mine_box" style="background-color: #191C23;padding-top: 20px;padding-bottom: 20px">
              <div class="MyHeader">
                <img class="head" :src="`./static/head/${UserInfo.header}`" />
              </div>
              <div style="margin-top: 0px;margin-left: 10px;display: flex">
                <div class="userName">
                  <div style="font-size: 22px">{{ UserInfo.nickname?UserInfo.nickname:UserInfo.username.substring(0,15)+'****'}}</div>
                  <div>
                    <img :src="`./static/icon/vip${UserInfo.vip_level}.png`"  width="60px"/>
                  </div>
                  <div style="font-size: 15px;color: #98A0AB" v-show="UserInfo.vip_level>=3">
                    {{ $t("user.default[2]") }}:<span style="color: #e7e7e7">{{ UserInfo.idcode }}</span>
                    <van-icon name="./static/icon/copy.png" style="margin-left: 10px" class="copy-qb" @click="copyCode" size="18"/>
                  </div>
                </div>
                <div style="margin-left: -10px;text-align: center;margin: 0 auto;margin-top: 5px">
                  <van-icon name="./static/icon/setting.png" size="30" @click="onClickRight(0)"/>
                  <div>{{$t('common7[3]')}}</div>
                </div>
              </div>
            </div>
        <div style="margin-top: 5px;text-align: left;margin-right: 15px">

<!--          <van-button size="min" color="#F0B80D" to="/busup" style="width: 80px;height: 28px;margin-left: 20px">-->
<!--            <span style="font-size: 13px">-->
<!--            {{ $t("wallet.default[13]") }}</span></van-button>-->
        </div>
      </div>
      <div style="display: flex">
        <div class="Menu2item1" style="color: #fff;line-height: 28px">
          <div style="display: flex;margin-left: -8px">
            <van-icon name="./static/icon/b0.png" size="22"/>
            <div style="color: #98A0AB;margin-top: -3px;text-align:left;font-size: 14px">{{ $t("wallet.default[9]") }}(USDT)</div>
          </div>
          <div style="font-size: 18px"> {{ Number(UserInfo.balance).toFixed(2) }} </div>
          <div>
            <van-button color="#F5C73A" size="small" style="margin: 10px auto;width: 70%;text-align: center"
                        @click="gotoRe" round
            ><span style="color:#000">{{ $t("wallet.default[1]") }}</span>
            </van-button>
          </div>
        </div>
        <div class="Menu2item1" style="color: #fff;line-height: 28px">
          <div style="display: flex;margin-left: 5px;color: #98A0AB">
            <van-icon name="./static/icon/b2.png" size="22"/>
            <div style="margin-left: 5px;margin-top: -3px;"> {{ $t("wallet.default[9]") }}({{InitData.usdtinfo.sign}})</div>
          </div>
          <div style="font-size: 18px"> {{ Number(UserInfo.balance2).toFixed(2) }} </div>
          <div>
            <van-button color="#F5C73A" size="small" style="margin: 10px auto;width: 70%;text-align: center"
                        @click="gotoWithdraw" round
            ><span style="color:#000">{{ $t("withdraw2[0]") }}</span>
            </van-button>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;background-color: #191C23;color: #98A0AB">
        <van-grid class="Menu"
                  :column-num="3"
                  gutter="4">
          <van-grid-item to="/user/wallet">
            <div style="border-radius: 50%">
              <img :src="`./static/icon/user_wallet.png`" style="height: 3.7rem" />
            </div>
            <div>{{ $t('user.menu[0]') }}</div>
          </van-grid-item>
          <van-grid-item to="/user/teamReport">
            <div style="border-radius: 50%">
              <img :src="`./static/icon/user_team.png`" style="height: 3.7rem" />
            </div>
            <div>{{ $t('user.menu[1]') }}</div>
          </van-grid-item>
          <van-grid-item to="/user/bindAccount">
            <div style="border-radius: 50%">
              <img :src="`./static/icon/user_bind.png`" style="height: 3.7rem" />
            </div>
            <div>{{ $t('user.menu[2]') }}</div>
          </van-grid-item>
          <van-grid-item to="/user/fundRecord">
            <div style="border-radius: 50%">
              <img :src="`./static/icon/user_account.png`" style="height: 3.7rem" />
            </div>
            <div>{{ $t('user.menu[3]') }}</div>
          </van-grid-item>
          <van-grid-item to="/help">
            <div style="border-radius: 50%">
              <img :src="`./static/icon/user_introduce.png`" style="height: 3.7rem" />
            </div>
            <div>{{ $t('user.menu[4]') }}</div>
          </van-grid-item>
          <van-grid-item :to="`/user/invite/${UserInfo.idcode}/${UserInfo.idcode}`">
            <div style="border-radius: 50%">
              <img :src="`./static/icon/user_gift.png`" style="height: 3.7rem" />
            </div>
            <div>{{ $t('home.menu[2]') }}</div>
          </van-grid-item>
        </van-grid>
      </div>
      <div style="margin: 0 auto;text-align: center;padding: 10px;margin-top: 10px">
        <van-button color="#191C23" style="margin: 10px auto;width: 95%;height: 60px"
                    @click="show_logout=true" round
        ><span style="color: #F5C73A;font-size: 20px">{{ $t("user.menu[5]") }}</span>
        </van-button>
      </div>
      <div style="height: 40px"></div>
    </div>
    </div>
    <Footer v-if="$route.name == 'user'" />
    <van-popup
            v-model="showMsg"
            style="background: transparent; width: 80%; text-align: center"
            @closed="$parent.isNotice = true"
    >
    <div class="popupBg">
      <dl class="ScrollBox NoticePopup" style="height:350px">
        <dt class="popup_title">
          <div>
              <span>{{
                msg.title
              }}</span>
          </div>
        </dt>
        <dd v-html="msg.content" style="color: #000"></dd>
      </dl>
    </div>
    <a class="close" href="javascript:;" @click="showMsg = false">
      <img src="../../../public/static/images/pupup_false.png" />
    </a>
    </van-popup>
    <van-dialog v-model="show_logout"  :title="$t('my[0]')" @confirm="$Model.Logout()"
                :confirmButtonText="$t('common[2]')" :cancelButtonText="$t('common[3]')"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="show_logout=false">
    </van-dialog>
  </div>
</template>

<script>
import config from "@/config";
import Clipboard from "clipboard"

const AppDown = config.AppDown;
export default {
  name: "User",
  components: {},
  props: [],
  data() {
    return {
      statisticalData: {},
      uid: "", //用户ID
      InferiorList: "",
      show_logout:false,
      sellDesc:'',
      agent_type:0,
      commDesc:'',
      userInfo:{},
      showMsg:false,
      msg:{"title":'aaa',"content":'eee'},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.sellDesc = this.$t('wallet.default[11]')+"(USDT)";
    this.commDesc = this.$t('wallet.default[12]')+"("+this.InitData.usdtinfo.sign+")";

    var token = localStorage.getItem("Token");

    this.$Model.GetMsg(data=>{
      if (data.code == 1) {
        this.msg = data['data'];
        if (this.msg['title'].length>0&&this.msg['content'].length>0){
          this.showMsg = true;
        }
      }
    });
    this.$Model.GetUserInfo((data) => {
      if (data.code == 1) {
        this.uid = data.info.uid;
        this.userInfo = data.info;
        // this.agent_type = data.info['agent_type'];
        this.$Model.getInferiorList({ uid: this.uid, token: token }, (data) => {
          this.InferiorList = data.list;
          console.log(data.list);
        });
      }
    });
    this.$Model.GetStatisticsInfo((data) => {
      if (data.code == 1) {
        this.statisticalData = data.info;
      }
    });
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    copyCode() {
      var that = this;
      var copyValue = this.UserInfo.idcode;
      let clipboard = new Clipboard('.copy-qb', {
        text: () => {
          return copyValue
        }
      })
      clipboard.on('success', () => {
        that.$Dialog.Toast(this.$t('recharge.label[5]'));
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        that.$Dialog.Toast(this.$t('taskOrder2[3]'));
        clipboard.destroy()
      })
    },
    gotoRe(){
      this.$router.push("/user/mixrecharge");
    },
    onClickRight(index){
      if (index==1){
        this.$router.push("/user/msglist");
      }else{
        this.$router.push("/user/info");
      }
    },
    gotoWithdraw(){
      let info = this.userInfo;
      if (info.withdraw_body&&info.withdraw_body.length>0){
        this.msg['title'] = info.withdraw_title;
        this.msg['content'] = info.withdraw_body;
        this.showMsg = true;
        return;
      }
      let sold_count = this.userInfo['sold_count'];
      // let withdraw_order_count = this.userInfo['withdraw_order_count'];
      // let count = withdraw_order_count-sold_count;
      // if (count>0){
      //   let msg = this.$t('taskOrder2[4]')+count
      //   this.$Dialog.Toast(msg);
      //   return;
      // }

      this.$router.push('/user/withdraw');
    },
    goDown() {
      if (window.plus) {
        this.$router.push("/AppDown");
      } else {
        var appUrl = localStorage["AppUrl"];
        this.$Util.OpenUrl(appUrl);
      }
    },
    //复制成功
    clipboardSuccessHandler({ value, event }) {
      this.$toast({
        message: "Başarıyla kopyala",
      });
      console.log(value);
    },
    copyAddressError(e) {
      this.$toast({
        message: "复制失败",
      });
    },
  },
};
</script>
<style scoped>
  .PageBox {
    color: #e7e7e7;
    padding: 0;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }

  .header_bg {
    width: 100%;
    top: 0;
    z-index: 0;
    height:300px;
  }


.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 72px;
  height: 72px;
}
.MyHeader img {
  width: 100%;
}

.Mine_box {
  padding: 2px;
  overflow: hidden;
  margin-left: 10px;
  flex-shrink: 0;
  display: flex;
}

  .Menu2item1 {
    margin-top: -10px;
    background-color: #191C23;
    line-height: 25px;
    margin-left: 10px;
    width: 48%;
    text-align: center;
    padding: 10px;
  }

.MyHeaderStatus {
  width: max-content;
  padding: 0 7px;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 20px;
  background: #F3C13A;
  color:#fff;
  line-height: 25px;
  display: flex;
  text-align: center;
}

.user_vip {
  width: 44px;
  height: 19px;
  background: url(../../../public/static/icon/user_vip01.png) no-repeat;
  background-size: 100% 100%;
  margin-left: 13px;
  color: #4e51bf;
  font-style: italic;
  text-align: center;
  line-height: 19px;
  padding-right: 5px;
  margin: 5px 0 9px 13px;
  font-weight: bold;
}

.MyHeaderStatus .MyHeaderStatus_radius {
  width: 8px;
  height: 8px;
  background: #ff93a4;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 2px;
}

.userName {
  width: 260px;
  font-size: 21px;
  color:#fff;
  font-family: emoji;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tool00 {
  width: 95%;
  border-radius: 10px;
  background-color: #191C23;
  box-shadow: 0 1px 3px #dbdbdbdb;
  overflow: hidden;
  z-index: 99;
  padding: 0 5px;
  align-items: center;
  margin-left: 10px;
  margin-top: 20px;
  padding: 20px;
}

  .tool01 {
    width: 95%;
    border-radius: 10px;
    background-color: #191C23;
    overflow: hidden;
    z-index: 99;
    color: #aaaaaa;
    padding: 0 5px;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
  }

  .tool02 {
    width: 95%;
    border-radius: 10px;
    background-color: #191C23;
    overflow: hidden;
    z-index: 99;
    color:#fff;
    padding: 0 5px;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
  }

  .tool02 >>> .van-cell {
    padding: 17px 0;
    background-color: #191C23;
  }

.close img {
  width: 36px;
  margin-top: 20px;
}

.UserInfo .info0 {
  margin-top: -255px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info {
  margin-top: -185px;
  z-index: 10;
  /*background-color: #151d31;*/
  /*margin-top: 10px;*/
  color: #fff;
  position: relative;
  z-index: 3;
  background: transparent none repeat scroll 0% 0%;
  position: relative;
  padding: 10px 16px 6px 20px;
}

  .tool02 >>> .van-cell__title {
    color: #e7e7e7;
  }

.UserInfo .info .van-cell__title {
  font-size: 12px;
}

.UserInfo .info .van-cell__label {
  font-size: 14px;
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info .van-cell__value {
  flex: none;
  line-height: 1.3;
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info .van-cell__value a {
  text-align: center;
  /*color: #fff;*/
  display: block;
}

.mygrid {
  padding: 5px 10px;
}

  .PageBox>>> .van-dialog {
    background-color: #191C23;
    color: #aaaaaa;
  }

  .PageBox>>> .van-dialog__confirm{
    background-color: #191C23;
    color: #13C465;
  }

  .van-dialog>>>.van-cell {
    background: #353C4A;
    color: #fff;
  }
  .PageBox>>> .van-dialog__cancel{
    background-color: #191C23;
    color: #cccccc;
  }

.mygridvalue {
  color:#fff0cd;
}
.top3 >>> .van-grid-item__content {
  padding: 8px 8px 12px !important;
}
.top2 {
  padding: 0 20px;
}
.top2 >>> .van-cell__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.top2 >>> .van-cell__title > span {
  font-size: 17px;
}
.top2 >>> .van-cell__title .van-cell__label {
  padding: 0px 0;
  color:#ABABAB;
  font-size: 12px;
}
.top3 >>> .van-grid-item {
  width: 30%;
}
.top3 >>> .van-icon img {
  width: 31px;
  height: 31px;
}
.top3 >>> .van-grid-item__text {
  font-size: 13px;
  color:#fff;
  font-family: Alibaba;
  font-weight: normal;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.top4 {
  padding: 0 15px;
}

.top4 >>> .van-cell {
  display: flex;
  justify-content: center;
  padding: 7px 16px;
}

.top4 >>> .van-cell .van-cell__title {
  flex: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* color:#fff; */
}
.top4_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.top4_box >>> .van-cell {
  background: #4e51bf;
  border-radius: 10px;
  padding: 0px 15px;
  margin: 0 auto;
  width: max-content;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}

.top4_box >>> .van-cell__title span {
  color: #fff !important;
  font-size: 13px !important;
}

.top4 >>> .van-cell__title span {
  font-size: 13px;
  color:#fff;
  font-family: Alibaba;
  font-weight: normal;
}

.top4 >>> .van-grid-item {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 15px;
}

.top4 >>> .border_none {
  border-bottom: none;
  margin-bottom: 0;
}

.top4 >>> .padding_0 .van-cell {
  padding: 10px 0;
}
.Menu {
  /*border-top: 10px #0e1526 solid;*/
  margin-top: 5px;
  line-height: 22px;
}

.Menu >>> .van-cell {
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 0px 0px;
}

.Menu >>> .van-cell__label {
  font-size: 18px;
  color: #fff;
  text-align: center;
}


.Menu >>> .van-grid-item__content {
  background: transparent none repeat scroll 0% 0%;
  padding: 16px 0px;
}

</style>
